const faqs = [
  {
    question: "Documentación para viajar a Canadá",
    answer: (
      <div>
        <p>-  Visado: Antes de viajar a Canadá desde México, es importante verificar si necesitas una visa para ingresar al país. Afortunadamente, los ciudadanos mexicanos que deseen visitar Canadá como turistas no necesitan obtener una visa de turista. Desde diciembre de 2016, los ciudadanos mexicanos pueden solicitar una Autorización Electrónica de Viaje (eTA, por sus siglas en inglés). Esta autorización se puede obtener en línea y tiene un costo asociado. La eTA es válida por un período de cinco años o hasta que expire tu pasaporte.</p><br/>
        <p>- Pasaporte: Para viajar a Canadá desde México, necesitarás un pasaporte válido. Asegúrate de que tu pasaporte tenga al menos seis meses de validez restante a partir de la fecha en que planeas ingresar a Canadá. Además, es esencial contar con al menos una página en blanco para los sellos de entrada y salida.</p><br/>
        <p>- Billete de avión: Como es de esperar, necesitarás un billete de avión para viajar a Canadá. Hay varias aerolíneas que ofrecen vuelos directos desde diferentes ciudades de México a destinos canadienses como Vancouver, Toronto y Montreal. Investiga y compara precios para encontrar la mejor opción que se ajuste a tu itinerario y presupuesto.</p><br/>
        <p>- Seguro de viaje: Aunque no es un requisito obligatorio, se recomienda encarecidamente contratar un seguro de viaje antes de viajar a Canadá. Un seguro de viaje te brindará protección en caso de enfermedad, accidentes o pérdida de equipaje durante tu estadía en el país. Asegúrate de leer detenidamente los términos y condiciones de tu póliza para comprender qué coberturas están incluidas.</p><br/>
        <p>- Documentos de respaldo: Es importante llevar contigo ciertos documentos de respaldo al viajar a Canadá. Estos pueden incluir una copia de tu eTA, reservas de hotel, itinerario de viaje, comprobantes de solvencia económica y cualquier otro documento que pueda respaldar el propósito de tu visita. Aunque es poco probable que se te soliciten todos estos documentos al ingresar a Canadá, es mejor estar preparado por si las autoridades migratorias los solicitan.</p><br/>
        <p>- Seguro de viaje: Aunque no es un requisito obligatorio, se recomienda adquirir un seguro de viaje que cubra cualquier emergencia médica o contratiempo durante tu estadía en Estados Unidos. Los servicios médicos en Estados Unidos son costosos, y contar con un seguro de viaje te brinda tranquilidad y protección en caso de necesitar asistencia médica.</p><br/>
        <p>Viajar a Canadá desde México es una experiencia maravillosa, pero es importante cumplir con los requisitos necesarios para ingresar al país. Asegúrate de tener una eTA válida, un pasaporte vigente y los documentos de respaldo necesarios.</p><br/>
    
      </div>
    ),
  },

 
];

export default faqs;
